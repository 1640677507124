import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import step_1 from "../../assets/how_works/step_1_b.png";
import step_2 from "../../assets/how_works/step_2_b.png";
import step_3 from "../../assets/how_works/step_3_b.png";
import step_4 from "../../assets/how_works/step_4_b.png";
import ButtonComp from "../../component/common/ButtonComp";
import { useNavigate } from "react-router-dom";
import css from "./JobAcceptedSection.module.css";
import accepted_job_baner from "../../assets/accepted_job_baner.png";
import { IoMdArrowForward } from "react-icons/io";
import profile from "../../assets/profile.png";

const JobAcceptedSection = () => {
  const navigate = useNavigate();

  const howWorksArr = [
    {
      img: step_1,
      guience_txt:
        "Choose a date and time that works for you, and tell us what you need.",
    },
    {
      img: step_2,
      guience_txt: "Done will find the perfect pro in minute.",
    },
    {
      img: step_3,
      guience_txt: "Your Done Pro gets the job done while you relax.",
    },
    {
      img: step_4,
      guience_txt:
        "Done syncs your job status, messages, and receipt all in one place.",
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid container alignItems={"stretch"} spacing={3} my={4}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Stack justifyContent={"center"} gap={2} sx={{ height: "100%" }}>
            <Typography variant="caption" className={css.first_heading_bl}>
              Secure your{" "}
              <Typography variant="caption" className={css.first_heading_or}>
                appointment{" "}
              </Typography>
              in just minutes.
            </Typography>

            <Typography
              variant="caption"
              className={css.job_accepted_description}
            >
              Lorem ipsum dolor sit amet consectetur. Varius ac quis felis
              fringilla morbi. Egestas augue sed neque malesuada faucibus neque
              donec vestibulum. Sit et libero lectus libero in sollicitudin
              morbi augue massa. Euismod accumsan nunc iaculis neque odio a
              massa.
            </Typography>

            <ButtonComp
              label={
                <>
                  Find me a pro &nbsp; &nbsp; <IoMdArrowForward />
                </>
              }
              onClick={() => navigate("/services")}
              style={{
                padding: "10px 37px",
                borderRadius: "40px",
                boxShadow:
                  "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
                background:
                  "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                fontWeight: "700",
                width: "fit-content",
              }}
            />
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            gap={4}
            className={css.job_accepted_banner}
          >
            <Stack className={css.job_accepted_content_wrapper} gap={1} justifyContent={"space-around"}>
              <Typography variant="caption" className={css.job_accepted_title}>
                Job Accepted
              </Typography>
              <Typography variant="caption" className={css.job_accepted_name}>
                Hi Jonas,
              </Typography>
              <Typography
                variant="caption"
                className={css.job_accepted_user_detail}
              >
                Meet john doe, your Appliance Install Specialist
              </Typography>

              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Stack className={css.user_profile_img_wrapper}>
                  <img src={profile} className={css.user_profile_img} />
                </Stack>
                <Stack justifyContent={"center"} >
                  <Typography
                    variant="caption"
                    className={css.job_accepted_user_name}
                  >
                    John Doe
                  </Typography>
                  <Typography
                    variant="caption"
                    className={css.job_accepted_certification}
                  >
                    Done Certified Pro
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JobAcceptedSection;
