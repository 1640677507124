import React, { useEffect, useState } from "react";
import { themeOrange } from "../../utils/colorTheme";
import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";
import { Divider, Stack } from "@mui/material";
import "./index.css";
import { FaCalendarDays } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";
import IconInput from "../IconInput/IconInput";
import { useDispatch, useSelector } from "react-redux";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { service_booking_keys } from "../../store/constants";

const FirstStep = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    date: localStorage.getItem(service_booking_keys.DATE) || "",
    time: localStorage.getItem(service_booking_keys.TIME) || "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { get_service_by_id_data } = useSelector(
    (state) => state.serviceManage
  );

  const _nextHandle = () => {
    dispatch(
      setJobsSteps({
        value: "2",
      })
    );
    localStorage.setItem(service_booking_keys.DATE, data.date);
    localStorage.setItem(service_booking_keys.TIME, data.time);
  };

  return (
    <div>
      <Stack sx={{ overflow: "hidden" }}>
        <Stack className="service-name">
          {get_service_by_id_data?.service?.name}
        </Stack>
        <Stack
          className="overflow_stepper"
          sx={{
            overflow: "auto",
          }}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            className="stepperClass"
            sx={{
              minWidth: "260px",
            }}
          >
            <Stepper
              color={themeOrange}
              fontSize="12px"
              fontColor="black"
              steps={[
                { label: "SCHEDULE" },
                { label: "JOB DETAILS" },
                { label: "CONFIRM" },
              ]}
              currentStep={1}
            />
          </Stack>
        </Stack>

        <Stack>
          <Divider sx={{ marginTop: "50px" }} />
          <Stack mt={3} className="service-sub-name">
            When should we send someone?
          </Stack>
          <Stack gap={3}>
            <Stack gap={1}>
              <Stack mt={1} className="availible-date">
                Select available date and times
              </Stack>
              <Stack sx={{ width: "100%" }}>
                <IconInput
                  id={"date"}
                  // disabled={true}
                  placeholder="Add Date"
                  first_icon={<FaCalendarDays size={25} color={themeOrange} />}
                  // second_icon={<FiPlus size={32} color={themeOrange} />}
                  style={{
                    width: "100%",
                    padding: "14px",
                    paddingLeft: "60px",
                    borderRadius: "10px",
                    margin: "0px",
                  }}
                  onChange={(e) => setData({ ...data, date: e.target.value })}
                  defaultValue={data.date}
                  // type={"date"}
                />
              </Stack>
            </Stack>
            <Stack gap={1}>
              <Stack className="Timing-constraints">Timing constraints</Stack>
              <Stack>
                <textarea
                  defaultValue={data.time}
                  id={"time"}
                  rows="4"
                  cols="50"
                  placeholder="e.g.Baby is napping from 3-4pm. Please do not arrive during those times."
                  value={data.time}
                  onChange={(e) => setData({ ...data, time: e.target.value })}
                  style={{
                    borderColor: themeOrange,
                    borderRadius: "10px",
                    padding: "10px",
                    resize: "none",
                  }}
                ></textarea>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: "50px" }} />

          {/* <Stack className="next-btn" onClick={_nextHandle} my={2}>
          Next
        </Stack> */}

          <Stack
            onClick={data.time && data.date ? _nextHandle : () => {}}
            className={`next-btn ${data.time && data.date && "next_btn_hov"}`}
            sx={{
              backgroundColor: data.time && data.date ? themeOrange : "grey",
              mb: 2,
            }}
          >
            Next
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default FirstStep;
