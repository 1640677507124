import React, { useEffect, useState } from "react";
import css from "./Contact.module.css";
import Footer from "../../screens/Footer/Footer";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ButtonComp from "../../component/common/ButtonComp";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa6";
import side_contact_banner from "../../assets/side_contact_banner.jpg";
import Navbar from "../../component/navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { contactUsAsync } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import service_man from "../../assets/service_man_contact_us.jpg";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { themeOrange } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const navigate = useNavigate()

  const { contact_us_status } = useSelector((state) => state.userAuth);

  const isLoading = contact_us_status === asyncStatus.LOADING;

  const _handleSubmit = () => {
    dispatch(contactUsAsync(data));
  };

  const _clearDataState = () => {
    setData({
      name: "",
      email: "",
      message: "",
    });
  };

  const _isEmpty = () => {
    const { name, email, message } = data;

    return !name || !email || !message;
  };

  const fields = [
    {
      _id: 1,
      label: "Full Name",
      value: data.name,
      onChange: (e) => setData({ ...data, name: e.target.value }),
      type: "text",
      disabled: false,
    },
    {
      _id: 1,
      label: "E-mail",
      value: "info@donerightaway.com",
      onChange: (e) => setData({ ...data, name: e.target.value }),
      type: "email",
      disabled: true,
    },
  ];

  const vendorFields = [
    {
      _id: 2,
      label: "E-mail",
      value: data.email,
      onChange: (e) => setData({ ...data, email: e.target.value }),
      type: "email",
      disabled: false,
    },
    {
      _id: 2,
      label: "Phone Number",
      value: `+1 (289) 400-4962`,
      onChange: (e) => setData({ ...data, email: e.target.value }),
      type: "text",
      disabled: true,
    },
    {
      _id: 3,
      label: "Message",
      value: data.message,
      onChange: (e) => setData({ ...data, message: e.target.value }),
      type: "text",
      disabled: false,
    },
  ];

  useEffect(() => {
    if (contact_us_status === asyncStatus.SUCCEEDED) {
      _clearDataState();
    }
  }, [contact_us_status]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            className={css.main_heading}
            sx={{
              fontSize: {
                xl: "72px",
                lg: "72px",
                md: "72px",
                sm: "42px",
                xs: "42px",
              },
              textAlign: "center",
            }}
          >
            Contact Us
          </Typography>
        </Stack>
      </div>
      <Container maxWidth="lg" sx={{ position: "relative" }}>
        <Grid
          container
          alignItems={"stretch"}
          sx={{
            position: "relative",
            top: "-100px",
            backgroundColor: "white",
            boxShadow: "0px 32px 46px -4px rgba(212,212,212,1)",
          }}
        >
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <Stack sx={{ height: "100%" }} p={6} gap={4}>
              <Stack direction={"row"} className={css.heading_wrapper}>
                <Typography
                  sx={{ color: "black", fontWeight: "700", fontSize: "40px" }}
                >
                  Contact Us
                </Typography>
              </Stack>

              <Grid container alignItems={"stretch"} spacing={3}>
                {fields.map(
                  ({ _id, label, value, onChange, type, disabled }, i) => {
                    return (
                      <Grid key={_id} item xl={6} lg={6} md={6} sx={12} xs={12}>
                        <input
                          placeholder={label}
                          type={type}
                          value={value}
                          onChange={onChange}
                          className={css.inp}
                          disabled={disabled}
                        />
                      </Grid>
                    );
                  }
                )}
                {vendorFields.map(
                  ({ _id, label, value, onChange, type, disabled }, i) => {
                    const isTextArea = _id === 3;
                    return isTextArea ? (
                      <Grid
                        key={_id}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sx={12}
                        xs={12}
                      >
                        <textarea
                          placeholder={label}
                          id={"time"}
                          rows="4"
                          cols="50"
                          value={value}
                          onChange={onChange}
                          className={css.inp}
                          style={{
                            resize: "none",
                            width: "100%",
                          }}
                        ></textarea>
                      </Grid>
                    ) : (
                      <Grid item xl={6} lg={6} md={6} sx={12} xs={12}>
                        <Box key={_id}>
                          <input
                            placeholder={label}
                            type={type}
                            value={value}
                            onChange={onChange}
                            className={css.inp}
                            disabled={disabled}
                          />
                        </Box>
                      </Grid>
                    );
                  }
                )}
              </Grid>

              <ButtonComp
                disabled={_isEmpty()}
                label={
                  isLoading ? (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  ) : (
                    "Contact Us"
                  )
                }
                style={{
                  background: _isEmpty()
                    ? "lightgrey"
                    : "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                  boxShadow:
                    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",

                  padding: "10px 30px",
                  width: "100%",
                  borderRadius: "5px",
                  height: "40px",
                }}
                onClick={_handleSubmit}
              />
            </Stack>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Stack
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              sx={{
                height: {
                  xl: "100% !important",
                  lg: "100% !important",
                  md: "100% !important",
                  sm: "88vh !important",
                  xs: "80vh !important",
                },
                width: "100%",
              }}
            >
              <Stack
                justifyContent={"flex-end"}
                gap={3}
                className={css.service_man_img}
                p={6}
                pb={2}
              >
                <Typography className={css.banner_img_txt}>LETS</Typography>
                <Typography
                  className={css.banner_img_txt}
                  sx={{ color: "white !important" }}
                >
                  GROW
                </Typography>
                <Typography className={css.banner_img_txt}>TOGETHER</Typography>

                <button
                  onClick={() => navigate("/support")}
                  className={css.contact_us_btn}
                >
                  Support{" "}
                  <MdKeyboardDoubleArrowRight style={{ fontSize: "15px" }} />
                </button>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <IconButton
                    sx={{
                      transition: "all  0.3s ease-in-out",
                      borderRadius: "10px",
                      padding: "5px",
                      ":hover": {
                        backgroundColor: themeOrange,
                      },
                    }}
                  >
                    <FaFacebook style={{ color: "white", fontSize: "20px" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      transition: "all  0.3s ease-in-out",
                      borderRadius: "10px",
                      padding: "5px",
                      ":hover": {
                        backgroundColor: themeOrange,
                      },
                    }}
                  >
                    <FaInstagram style={{ color: "white", fontSize: "20px" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      transition: "all  0.3s ease-in-out",
                      borderRadius: "10px",
                      padding: "5px",
                      ":hover": {
                        backgroundColor: themeOrange,
                      },
                    }}
                  >
                    <FaTwitter style={{ color: "white", fontSize: "20px" }} />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Box mt={10}>
        <Footer />
      </Box>
    </div>
  );
};

export default ContactUs;
