import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { themeText } from "../../../../utils/colorTheme";
import "./index.css";

export const ServicesCard = (props) => {
  const { src, heading, onClick, sub_heading } = props;

  return (
    <Stack
      onClick={onClick}
      mb={1}
      className="card-container"
    >
      <Stack>
        <img className="img-size" src={src} />
      </Stack>
      <Stack
        py={3}
        px={2}
        mt={1}
        gap={1}
        justifyContent={"center"}
        alignItems={"flex-start"}
        color={themeText}
      >
        <p className="card-heading-service">{heading}</p>
        <p  className="service-sub-heading">{sub_heading}</p>
      </Stack>
    </Stack>
  );
};
