import { IoLogOut } from "react-icons/io5";
import { GrAppsRounded } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { RiMessageLine } from "react-icons/ri";
import { IoMdCheckboxOutline } from "react-icons/io";
import { PiNotePencil } from "react-icons/pi";
import Test from "../pages/Test";
import DashboardHome from "../screens/Dashboard/VendorDashboard/DashboardHome";
import JobRequest from "../screens/Dashboard/JobRequest";
import JobDone from "../screens/Dashboard/JobDone";
import Messages from "../screens/Dashboard/DashboardMessage";
import DashboardSettings from "../screens/Dashboard/DashboardSettings";
import DashboardUserSide from "../screens/UserDashboard/DashboardUserSide";
import MyJobs from "../screens/UserDashboard/MyJobs";
import SettingsUser from "../screens/UserDashboard/SettingsUser";
import { RxDashboard } from "react-icons/rx";
import MessageUser from "../screens/UserDashboard/MessageUser";
import VendorPersonalDetails from "../screens/Dashboard/VendorProfileComplete/VendorPersonalDetails/VendorPersonalDetails";
import VendorPaymentSetup from "../screens/Dashboard/VendorProfileComplete/VendorPaymentSetup/VendorPaymentSetup";
import VendorSubmit from "../screens/Dashboard/VendorProfileComplete/VendorSubmit/VendorSubmit";
import VendorVerified from "../screens/Dashboard/VendorProfileComplete/VendorVerified/VendorVerified";
import VendorProfileVerification from "../screens/Dashboard/VendorProfileComplete/VendorProfileVerification/VendorProfileVerification";
import { RiExchangeDollarLine } from "react-icons/ri";
import Subscription from "../screens/UserDashboard/Subscription";

export const logoutPage = () => {
  return;
};

export const userDashboardRoutes = [
  {
    caption: "Dashboard",
    linkTo: "/",
    icon: <RxDashboard />,
    element: <DashboardHome />,
    list_in_sidebar: true,
    both: false,
    admin: true,
    user: false,
  },
  {
    caption: "Job Request",
    linkTo: "/job-req",
    icon: <PiNotePencil />,
    element: <JobRequest />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Job Done",
    linkTo: "/job-done",
    icon: <IoMdCheckboxOutline />,
    element: <JobDone />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Message",
    linkTo: "/message",
    icon: <RiMessageLine />,
    element: <Messages />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Settings",
    linkTo: "/settings",
    icon: <IoSettingsOutline />,
    element: <DashboardSettings />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
];

export const userFromUserSideRoutes = [
  {
    caption: "Dashboard",
    linkTo: "/",
    icon: <RxDashboard />,
    element: <DashboardUserSide />,
    list_in_sidebar: true,
    both: false,
    admin: true,
    user: false,
  },
  {
    caption: "MyJobs",
    linkTo: "/myjobs-user",
    icon: <PiNotePencil />,
    element: <MyJobs />,
    both: false,
    list_in_sidebar: true,
    both: false,
    admin: true,
    user: false,
  },
  {
    caption: "Message",
    linkTo: "/message-user",
    icon: <RiMessageLine />,
    element: <MessageUser />,
    admin: true,
    list_in_sidebar: true,
    both: false,
    admin: true,
    user: false,
  },
  {
    caption: "Settings",
    linkTo: "/settings",
    icon: <IoSettingsOutline />,
    element: <SettingsUser />,
    user: false,
    list_in_sidebar: false,
    both: false,
    admin: true,
    user: false,
  },
  {
    caption: "Subscriptions",
    linkTo: "/subscriptions",
    icon: <RiExchangeDollarLine />,
    element: <Subscription />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
];
