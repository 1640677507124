import React, { useEffect, useState } from "react";
import { themeOrange } from "../../utils/colorTheme";
import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";
import { Divider, Stack, IconButton, Typography } from "@mui/material";
import "./index.css";
import CustomAccordion from "../common/customAccordion/CustomAccordion";
import { MdLibraryAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { FiUpload, FiX } from "react-icons/fi";
import { setJobsImages } from "../../store/slices/services_slice";
import { service_booking_keys } from "../../store/constants";

const SecondStep = () => {
  const dispatch = useDispatch();
  const {
    get_service_by_id_status,
    get_service_by_id_data,
    get_service_by_id_error,
    jobs_images,
  } = useSelector((state) => state.serviceManage);
  const [selectedImages, setSelectedImages] = useState([
    ...(jobs_images || ""),
  ]);
  const [description, setDescription] = useState(
    localStorage.getItem(service_booking_keys.DESCRIPTION) || ""
  );

  const _backHandle = () => {
    dispatch(setJobsSteps({ value: "1" }));
  };

  const _nextHandle = () => {
    // dispatch(setNextStepSecond());
    dispatch(setJobsSteps({ value: "3" }));
    dispatch(setJobsImages(selectedImages));
    localStorage.setItem(service_booking_keys.DESCRIPTION, description);
  };

  const _handleUploadClick = () => {
    document.getElementById("upload-input").click();
  };

  const _handleDeleteClick = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const _handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);

    if (newFiles.length > 0) {
      setSelectedImages([...selectedImages, ...newFiles]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Stack>
        <Stack className="service-name">
          {get_service_by_id_data?.service?.name}
        </Stack>
        <Stack
          className="overflow_stepper"
          sx={{
            overflow: "auto",
          }}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            className="stepperClass"
            sx={{
              minWidth: "260px",
            }}
          >
            <Stepper
              color={themeOrange}
              fontSize="12px"
              fontColor="black"
              steps={[
                { label: "SCHEDULE" },
                { label: "JOB DETAILS" },
                { label: "CONFIRM" },
              ]}
              currentStep={2}
            />
          </Stack>
        </Stack>

        <Divider sx={{ marginTop: "50px" }} />
        <Typography mt={2} className="service-sub-name">
          What do you need done?
        </Typography>
        <Stack style={{ marginTop: "32px" }}>
          <CustomAccordion
            title="Pros recommend including these details:"
            content="Brand and model number(s)"
            marginLeft="30px"
          />
        </Stack>

        <Stack mt={2}>
          <textarea
            id={"description"}
            rows="4"
            cols="50"
            placeholder="e.g.something that needs to be fixed, installed, cleaned, etc."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{
              borderColor: themeOrange,
              borderRadius: "10px",
              padding: "10px",
              resize: "none",
            }}
          />
        </Stack>

        <Divider sx={{ marginTop: "50px" }} />
        <Typography mt={2} className="service-sub-name">
          Upload Photos
        </Typography>
        <Stack sx={{ marginTop: "32px" }}>
          <CustomAccordion
            title="Pros recommend including these details:"
            content={
              <div>
                <ul style={{ listStyleType: "none" }}>
                  <li>Pic of the appliance(s)</li>
                  <li>Pics of where the appliance(s) will be installed</li>
                  <li>Pics of the label showing model name and number</li>
                  <li>Pics of any parts or materials you are providing</li>
                </ul>
                <div style={{ marginLeft: "-30px", marginTop: "10px" }}>
                  These details are not needed if you are removing old
                  appliances.
                </div>
              </div>
            }
            marginLeft="30px"
          />
        </Stack>
        <Stack sx={{ marginTop: "32px" }} className="upload-image-container">
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              // height: "100%",
              // border: selectedImages ? "0.5px solid white" : "none",
              borderRadius: "10px",
              p: 1.5,
              cursor: "pointer",
              position: "relative",
              // width: "100%",
            }}
            gap={2}
            onClick={_handleUploadClick}
          >
            <input
              id="upload-input"
              type="file"
              multiple
              accept="image/*"
              onChange={_handleFileChange}
              style={{ display: "none" }}
            />
            <MdLibraryAdd size={25} color={themeOrange} />
            <Stack className="add-photo-title">Add Photos</Stack>
          </Stack>
        </Stack>

        <Stack sx={{ width: "100%" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            my={1}
            sx={{ overflow: "auto", maxWidth: "100%" }}
          >
            {selectedImages.length > 0 &&
              selectedImages.map((file, i) => {
                return (
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#d4d1d1",
                      overflow: "hidden",
                      borderRadius: "10px",
                      position: "relative",
                      flexShrink: "0",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        // objectFit: "contain",
                        borderRadius: "10px",
                      }}
                    />
                    {/* Delete button */}
                    <IconButton
                      onClick={() => _handleDeleteClick(i)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        color: "white",
                        zIndex: 1,
                        padding: "0px",
                        fontSize: "15px",
                      }}
                    >
                      <FiX />
                    </IconButton>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>

        <Divider sx={{ marginTop: "10px" }} />
        <Stack flexDirection={"row"} alignItems={"center"} gap={2} mb={2}>
          <Stack onClick={_backHandle} className="next-btn-outline">
            Back
          </Stack>
          <Stack
            onClick={
              description && selectedImages.length > 0 ? _nextHandle : () => {}
            }
            className={`next-btn ${
              description && selectedImages.length > 0 && "next-btn-hov"
            }`}
            sx={{
              backgroundColor:
                description && selectedImages.length > 0 ? themeOrange : "grey",
            }}
          >
            Next
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default SecondStep;
