import React, { useEffect } from "react";
import DashboardNavbar from "../../component/DashboardNavbar/DashboardNavbar";
import css from "./Support.module.css";
import Footer from "../../screens/Footer/Footer";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../../component/navbar/Navbar";
import { themeOrange } from "../../utils/colorTheme";

const Support = () => {
  const faqsData = [
    {
      category: "Services & Markets",
      questions: [
        {
          question: "What types of services can I request?",
          answer: [
            "Mobile Auto Detailing",
            "Mobile Auto Service",
            "Plumbing",
            "Pest Control",
            "Garage Door Repair",
            "Window & Eaves Cleaning",
            "Home Inspection",
            "Furniture Assembly",
            "Duct Cleaning",
            "Garbage Pickup",
            "Dry Cleaning",
            "Power Washing",
            "Handyman Services",
            "Lawn Maintenance",
            "Holiday Lights Installation",
            "Mobile Tire Change",
            "Appliance Installation",
            "Appliance Repair",
            "Locksmith Services",
            "Painting",
            "Moving & Delivery",
            "Gas Services",
            "BBQ Cleaning & Repair",
            "Carpet & Upholstery Cleaning",
            "Junk Removal",
            "Electrical Services",
            "Heating & Cooling",
            "Snow Removal",
            "$1 Flash Box",
          ],
        },
        {
          question: "Where can I request a service?",
          answer: [
            "Services can be requested for your home or office within the Greater Toronto Area and Ottawa. We plan to expand to additional cities in the near future.",
          ],
        },
      ],
      description:
        "We continuously expand our service categories. Feel free to suggest any new services you'd like us to offer.",
    },
    {
      category: "Account",
      questions: [
        {
          question: "Is there a subscription fee for my account?",
          answer: [
            "No subscription fee is required. Charges are only applied after the completion of a service request.",
          ],
        },
        {
          question: "Why do I need to provide my credit card information?",
          answer: [
            "Your credit card will be billed automatically once a service is completed. If you’re dissatisfied, we will either send a professional to address the issue or offer a refund (refer to our User Protection Plan for details).",
          ],
        },
      ],
    },
    {
      category: "Booking",
      questions: [
        {
          question: "Can I schedule a service in advance?",
          answer: [
            "Yes, you have the option to book either immediately (ASAP) or schedule for a later date.",
          ],
        },
        {
          question: "Can I request the same professional for future services?",
          answer: [
            "We’re pleased you had a great experience! Currently, your request will be sent to all available professionals in the relevant category to ensure prompt service. We are working on a feature that will allow you to request a specific professional based on their availability.",
          ],
        },
      ],
    },
    {
      category: "Appointment & Cancellation",
      questions: [
        {
          question: "Do I need to be present during the appointment?",
          answer: [
            "You or a representative (at least 18 years old) must be present for appointments that require entry into your home or office. For outdoor services, it is not mandatory but recommended to ensure proper service.",
          ],
        },
        {
          question: "What if I need to cancel my appointment?",
          answer: [
            "You may cancel without a fee until the professional is en route. Cancellations made after the professional is on their way incur a $25 fee. If the professional fails to arrive within a reasonable time after the appointment window, the cancellation fee will be waived.",
          ],
        },
        {
          question: "Can a professional cancel the appointment?",
          answer: [
            "A professional may cancel within 15 minutes of accepting the job without incurring a fee. Cancellations after this period but before the appointment window expires will result in a $25 fee. Failure to show up after a reasonable time post-appointment window will incur a $50 fee.",
          ],
        },
        {
          question: "Can I request a service at any time?",
          answer: [
            "Requests can be made anytime, though availability is typically higher during regular business hours.",
          ],
        },
      ],
    },
    {
      category: "Pricing",
      questions: [
        {
          question: "How are service charges determined?",
          answer: [
            "Each service has a rate card available for review. Rates are based on industry standards and may be hourly or based on specific metrics (e.g., amount of junk, carpet size). Minimum charges apply, and any materials used will be billed separately. Ensure you understand both the rate and minimum charge before requesting a service.",
          ],
        },
        {
          question: "Will I receive an estimate before the service starts?",
          answer: [
            "Rate cards provide initial pricing information. For further details on time or materials, we recommend communicating directly with the professional once the job is accepted. If you believe you’ve been overcharged, contact us for assistance.",
          ],
        },
      ],
    },
    {
      category: "Payment & Promotions",
      questions: [
        {
          question: "When is payment due?",
          answer: ["Your credit card will be charged upon service completion."],
        },
        {
          question: "Are promotional credits automatically applied?",
          answer: [
            "Yes, any available credits will be applied before billing your credit card.",
          ],
        },
        {
          question: "Is there a maximum limit on credits?",
          answer: [
            "No, there is no cap on the amount of credits you can earn.",
          ],
        },
        {
          question: "Do promotional credits expire?",
          answer: [
            "Yes, promotional credits have expiration dates. Check each promotion’s terms to ensure you use them before they expire.",
          ],
        },
      ],
    },
    {
      category: "User Protection Plan",
      questions: [
        {
          question: "What does the User Protection Plan include?",
          answer: [
            "If you are unsatisfied with the service, we will send a professional to rectify the issue or offer a refund. Details are available in the User Protection Plan.",
          ],
        },
      ],
    },
    {
      category: "Professionals",
      questions: [
        {
          question: "How can I be assured of the professional’s quality?",
          answer: [
            "We partner with top companies and ensure all professionals meet our high standards. If service quality does not meet expectations, contact us for resolution or a refund (refer to the User Protection Plan).",
          ],
        },
        {
          question:
            "Will I be informed of the professional assigned to my request?",
          answer: [
            "Yes, once a professional accepts the job, you will receive their name, company, contact information, rating, and picture.",
          ],
        },
        {
          question: "Are professionals insured and licensed?",
          answer: [
            "Yes, professionals who require insurance or licenses have provided proof of coverage before joining our platform.",
          ],
        },
      ],
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <DashboardNavbar /> */}
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ backgroundColor: "#362d2db3", height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            className={css.main_heading}
            sx={{
              fontSize: {
                xl: "72px",
                lg: "72px",
                md: "72px",
                sm: "42px",
                xs: "42px",
              },
              textAlign: "center",
            }}
          >
            Support
          </Typography>
        </Stack>
      </div>
      <Container maxWidth="xl">
        <Typography
          py={10}
          pb={10}
          className={css.intro_title}
          sx={{
            fontSize: {
              xl: "25px",
              lg: "25px",
              md: "25px",
              sm: "20px",
              xs: "20px",
            },
            textAlign: "center",
          }}
        >
          Frequently Asked Questions
        </Typography>

        {faqsData?.map(({ category, questions, description }) => {
          return (
            <Stack gap={1} py={1}>
              <Typography
                className={css.intro_description}
                sx={{
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "20px",
                    sm: "18px",
                    xs: "18px",
                  },
                }}
              >
                {category ?? ""}
              </Typography>
              <Box>
                <Stack gap={2}>
                  {questions?.map(({ question, answer }) => {
                    return (
                      <Accordion
                        sx={{
                          borderRadius: "10px !important",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={{
                            borderRadius: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {question ?? ""}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            backgroundColor: "#FEEEE9",
                            borderRadius: "10px",
                          }}
                        >
                          {answer?.map((data, i) => {
                            return <Typography key={i}>{data}</Typography>;
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Stack>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "rgba(44,44,44,1)",
                    fontSize: {
                      xl: "14px",
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    my: 1,
                  }}
                >
                  {description ?? ""}
                </Typography>
              </Box>
            </Stack>
          );
        })}
      </Container>

      <Box mt={10}>
        <Footer />
      </Box>
    </div>
  );
};

export default Support;
