import React, { useEffect, useState } from "react";
import "./SearchBar.css";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { searchServiceAsync } from "../../../services/services";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { themeOrange } from "../../../utils/colorTheme";

const SearchBar = ({ placeholder, onSearch, buttonText, initialQuery }) => {
  const [data, setData] = useState("");
  const [val, setVal] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { get_all_services_data } = useSelector((state) => state.serviceManage);

  useEffect(() => {
    if (isFocused || data.trim() !== "") {
      dispatch(searchServiceAsync({ search: "install" }));
    }
  }, [data, dispatch, isFocused]);

  useEffect(() => {
    if (initialQuery) {
      setData(initialQuery);
    }
  }, [initialQuery]);

  const _handleVal = (elm) => {
    setVal(elm);
    setData(elm.name);
    navigate(`/ServiceDetails/${elm?.id}`);
    console.log("elm?.id>>>>>>", elm?.id);
  };

  const filteredServices = data?.trim()
    ? get_all_services_data?.services?.filter((service) =>
        service?.name?.toLowerCase()?.includes(data?.toLowerCase())
      )
    : isFocused
    ? get_all_services_data?.services
    : [];

  const _handleSearch = () => {
    navigate(`/services?query=${encodeURIComponent(data)}`);
  };

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div className="search-container">
        <div className="right-content">
          <div>
            <FaSearch className="search-icon" />
          </div>
          <div style={{ width: "90%" }}>
            <input
              className="input-text"
              onChange={(e) => {
                setData(e.target.value);
                setVal(null);
              }}
              type="text"
              value={val ? val.name : data}
              placeholder={placeholder || "Select services"}
              style={{ width: "90%" }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
        </div>
        {data?.trim() && (
          <div style={{ height: "100%", padding: "5px 0px" }}>
            <button
              className="hero-btn"
              onClick={_handleSearch}
              style={{
                cursor: "pointer",
                background:
                  "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
              }}
            >
              {buttonText || "GO"}
            </button>
          </div>
        )}
      </div>
      {(data || isFocused) && (
        <Stack
          mt={1}
          alignItems={"flex-start"}
          borderRadius={2}
          sx={{
            position: "absolute",
            top: "100%",
            left: "0",
            width: "100%",
            zIndex: "1000",
            overflow: "auto",
            width: "100%",
            backgroundColor: "white",
            maxHeight: "320px",
          }}
        >
          {filteredServices?.length > 0
            ? filteredServices?.map((service, i) => (
                <Typography
                  key={i}
                  onMouseDown={() => _handleVal(service)}
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "start",
                    color: "black",
                    ":hover": {
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                  
                      color: "white",
                    },
                    borderTop: "1px solid #d3d3d38a",
                    borderBottomF: "1px solid #d3d3d38a",
                  }}
                  py={1}
                  px={2}
                >
                  {service?.name}
                </Typography>
              ))
            : data?.trim() && (
                <Typography
                  variant="caption"
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    color: themeOrange,
                    backgroundColor: "lightgray",
                    fontWeight: "500",
                  }}
                  py={1}
                  px={1}
                >
                  Need help finding the right service for you?{" "}
                  <Typography
                    variant="caption"
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "start",
                      color: themeOrange,
                      fontWeight: "500",
                    }}
                    onClick={() => navigate("contact_us")}
                  >
                    Contact us
                  </Typography>
                </Typography>
              )}
        </Stack>
      )}
    </div>
  );
};

export default SearchBar;
