import * as Yup from "yup";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const getValidationSchema = (formType) => {
  switch (formType) {
    case "signup":
      return Yup.object().shape({
        first_name: Yup.string()
          .transform((value) => value.trim()) // This will remove leading and trailing spaces
          .matches(
            /^[A-Za-z]/,
            "First name should not start with a number or special character"
          )
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("First name is required"),

        last_name: Yup.string()
          .transform((value) => value.trim()) // This will remove leading and trailing spaces
          .matches(
            /^[A-Za-z]/,
            "Last name should not start with a number or special character"
          )
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Last name is required"),

        email: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .email("Invalid email address")
          .matches(emailRegex, "Please enter a valid email address")
          .required("Email is required"),

        phone: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .matches(
            /^(?:\+?1[-.\s]?)?\(?([2-9][0-9]{2})\)?[-.\s]?[2-9][0-9]{2}[-.\s]?[0-9]{4}$/,
            "Invalid Canadian phone number"
          )
          .required("Phone number is required"),

        password: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .min(8, "Must be at least 8 characters")
          .required("Password is required"),

        confirmPassword: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password is required"),

        selectedServicesIds: Yup.array()
          .min(1, "Please select at least one service")
          .required("Service selection is required"),

        selectedServicesAreaIds: Yup.array()
          .min(1, "Please select at least one service area")
          .required("Service area selection is required"),
      });
    case "user_signup":
      return Yup.object().shape({
        first_name: Yup.string()
          .transform((value) => value.trim()) // This will remove leading and trailing spaces
          .matches(
            /^[A-Za-z]/,
            "First name should not start with a number or special character"
          )
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("First name is required"),

        last_name: Yup.string()
          .transform((value) => value.trim()) // This will remove leading and trailing spaces
          .matches(
            /^[A-Za-z]/,
            "Last name should not start with a number or special character"
          )
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Last name is required"),

        email: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .email("Invalid email address")
          .matches(emailRegex, "Please enter a valid email address")
          .required("Email is required"),

        phone: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .matches(
            /^(?:\+?1[-.\s]?)?\(?([2-9][0-9]{2})\)?[-.\s]?[2-9][0-9]{2}[-.\s]?[0-9]{4}$/,
            "Invalid Canadian phone number"
          )
          .required("Phone number is required"),

        password: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .min(8, "Must be at least 8 characters")
          .required("Password is required"),

        confirmPassword: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password is required"),
      });
    case "login":
      return Yup.object().shape({
        email: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .email("Invalid email address")
          .matches(emailRegex, "Please enter a valid email address")
          .required("Email is required"),
        password: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .min(8, "Must be at least 8 characters")
          .required("Password is required"),
      });
    case "forgot_password":
      return Yup.object().shape({
        email: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .email("Invalid email address")
          .matches(emailRegex, "Please enter a valid email address")
          .required("Email is required"),
      });

    case "otp":
      return Yup.object().shape({
        otp: Yup.string()
          .trim()
          .matches(/^[0-9]{4}$/, "OTP must be exactly 4 digits")
          .required("OTP is required"),
      });

    case "reset_password":
      return Yup.object().shape({
        password: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .min(7, "Must be at least 7 characters")
          .required("Password is required"),

        confirm_password: Yup.string()
          .transform((value) => value.trim()) // Trim leading/trailing spaces
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password is required"),
      });
    case "vendor_persona_details":
      return Yup.object().shape({
        first_name: Yup.string()
          .transform((value) => value.trim()) // This will remove leading and trailing spaces
          .matches(
            /^[A-Za-z]/,
            "First name should not start with a number or special character"
          )
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("First name is required"),

        last_name: Yup.string()
          .transform((value) => value.trim()) // This will remove leading and trailing spaces
          .matches(
            /^[A-Za-z]/,
            "Last name should not start with a number or special character"
          )
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Last name is required"),
        dob: Yup.date().required("Date of Birth is required"),
        country: Yup.string().required("Country is required"),
        street: Yup.string()
          .trim()
          .min(3, "Street is too short")
          .required("Street is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        postal_code: Yup.string()
          .trim()
          .matches(
            /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
            "Invalid postal code"
          )
          .required("Postal Code is required"),
      });
    case "vendor_payment_setup":
      return Yup.object().shape({
        account_holder_name: Yup.string()
          .transform((value) => value.trim()) // This will remove leading and trailing spaces
          .matches(
            /^[A-Za-z]/,
            "Account holder name should not start with a number or special character"
          )
          .min(2, "Account Holder Name is too short")
          .required("Account Holder Name is required"),
        routing_number: Yup.string()
          .matches(
            /^(\d{5}-\d{3}|\d{9})$/,
            "Routing Number must be either in the format XXXXX-YYY or a 9-digit number, containing only numbers"
          )
          .required("Routing Number is required"),
        account_number: Yup.string()
          .matches(/^\d{12}$/, "Account Number must be exactly 12 digits")
          .required("Account Number is required"),
      });
    case "vendor_check_email":
      return Yup.object().shape({
        email_code: Yup.string()
          .trim()
          .matches(/^[0-9]{4}$/, "Email OTP must be exactly 4 digits")
          .required("Email OTP is required"),
        mobile_code: Yup.string()
          .trim()
          .matches(/^[0-9]{4}$/, "Mobile OTP must be exactly 4 digits")
          .required("Mobile OTP is required"),
      });

    default:
      return Yup.object(); // No validation for unknown forms
  }
};
