import React, { useRef, useState } from "react";
import "./index.css";
import { Avatar, Grid, IconButton, Stack, Typography } from "@mui/material";
import david_img from "../../assets/david.jpg";
import { SlBadge } from "react-icons/sl";
import { BsQuestionCircleFill } from "react-icons/bs";
import { AiFillLike } from "react-icons/ai";
import { themeBlue, themeOrange } from "../../utils/colorTheme";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { AiFillDislike } from "react-icons/ai";
import { useSelector } from "react-redux";
import { imgUrl } from "../../utils/imageUrl";

// Import Swiper components and modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const VendorReviews = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [expandedReviews, setExpandedReviews] = useState({});
  const {
    get_all_services_status,
    get_all_services_data,
    get_service_by_id_data,
    get_all_services_error,
  } = useSelector((state) => state.serviceManage);
  console.log("get_service_by_id_data", get_service_by_id_data?.reviews || "");

  const _toggleReviewExpansion = (index) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the expanded state for this review
    }));
  };

  const _handleReviewText = (review, isExpanded) => {
    if (isExpanded) {
      return review; // Show full review if expanded
    } else {
      return review.length > 150 ? review.slice(0, 150) + "..." : review; // Show truncated review if collapsed
    }
  };

  // const vendorReviewsData = [
  //   {
  //     name: "Watson",
  //     certification: "Done Certified Pro",
  //     rating: 985,
  //     isRated: true,
  //     likes: 75,
  //     communication_skills: ["Friendly", "Patient", "Good quality"],
  //     customer_joining_date: "Feb 2021 Toronto, ON",
  //     review_description:
  //       "Watson was fantastic! He did the job quickly and with great attention to detail. Highly recommended!",
  //     img: "https://cdn.hackaday.io/images/3553251501638077867.png",
  //   },
  //   {
  //     name: "Michael",
  //     certification: "Done Certified Pro",
  //     rating: 1103,
  //     isRated: false,
  //     likes: 130,
  //     communication_skills: ["Efficient & Quick", "Knowledgeable"],
  //     customer_joining_date: "Jan 2019 Mississauga, ON",
  //     review_description:
  //       "Michael was very professional and efficient. We are extremely satisfied with his work.",
  //     img: "https://avatars.mds.yandex.net/i?id=8850b88ae95ed8c844dec97631719337a098a322-10551030-images-thumbs&n=13",
  //   },
  //   // {
  //   //   name: "Sarah",
  //   //   certification: "Done Certified Pro",
  //   //   rating: 970,
  //   //   isRated: true,
  //   //   likes: 68,
  //   //   communication_skills: ["Friendly", "Good quality", "Efficient & Quick"],
  //   //   customer_joining_date: "Mar 2020 Hamilton, ON",
  //   //   review_description:
  //   //     "Sarah arrived on time and completed the job quickly and efficiently. We will definitely call her again.",
  //   //   img: "https://avatars.mds.yandex.net/i?id=518b98059e73df656a39ff75ad96596a520c64dc-4828133-images-thumbs&n=13",
  //   // },
  //   // {
  //   //   name: "James",
  //   //   certification: "Done Certified Pro",
  //   //   rating: 1045,
  //   //   isRated: false,
  //   //   likes: 115,
  //   //   communication_skills: ["Knowledgeable", "Friendly", "Good quality"],
  //   //   customer_joining_date: "Nov 2018 Burlington, ON",
  //   //   review_description:
  //   //     "James was knowledgeable and friendly. He completed the job to a high standard. Would hire again.",
  //   //   img: "https://avatars.mds.yandex.net/i?id=ea48d2c468e1b83267820bb493ef916235dc3836-7753557-images-thumbs&n=13",
  //   // },
  //   // {
  //   //   name: "Laura",
  //   //   certification: "Done Certified Pro",
  //   //   rating: 999,
  //   //   isRated: true,
  //   //   likes: 92,
  //   //   communication_skills: ["Friendly", "Efficient & Quick"],
  //   //   customer_joining_date: "Apr 2021 Oakville, ON",
  //   //   review_description:
  //   //     "Laura did a great job. She was friendly and very efficient. Would definitely recommend her.",
  //   //   img: "https://yandex-images.clstorage.net/4mMJW8218/11e1f3YFmck5/b1c9zBKoce6XAaXnC7S90_Q5tRfUFcrMkGUe9x6fYov6826JmrL_6wz7ZB7It0Ac9lVjIeKAg-UDNGdCwSxIDAsT-fgDLaSKxtURJ3sk_CdF7TUTpdDqfeHQewOLOhJb-pX4e5iVyVzaqdMJPwOQLEMVAoUn0BYjldUCwCIbeDvulUxeVwrwrpOAhPU5pPzF5rvEsofjeGwnoTpzvnjmzB0HmanZx0v9SSrji4zTAm3jZRJRFxDFwDYBEPBzurpJD2dcr5SJI7_h83d0icbeZdXY5fIWcWi85ZAaUK6ohZ4_xmqreddrjIubAdvdsjGvo0UQUvRCVuJ244AwwHm4qN5Hv9-UCgOP8mK0pTnmDCRlqyTjddIqTedAuUMMu7W_rQUoigkUes4bOxD6LXKCrhJ3kmK1o3SS1mJhgNJay2ocRL5ftHmxTDDTRLa5hqwVtMn182fSWpwUI2szHZt2Do0WWXjoJti_i2uB2Dygcb2RdQFQt_A14KTSklHhOzh4fDc-biSKYewDAnfVORRdNCQrRECHAElPx2KpgL6Zdi5slumrGpWb3IrKQes9kPD8MtZyY0WRRCFWo3EwQnkaaS0GHc02WoOe00BlxLtl7rVnyfSxZtFq_YWCq4DPiNef3VT76ioW2t15-VJ5PwLzTYBFYgNkYtZgpFKAw1H66Knc1ByfZMqxbNDDl3ZrFp4nVMiFIcSRq3w2ooiw3-gnT6z0exq5J7uPSThiWC1SMgxA1dOzN2GG4kVDkTPAe3lpTZVMLoT5034hwub3-UfMtgT6piBlIasv9rLoUCy556_elLnLqaWZ_6k64vk-4rMNAncT0IXztcNXE8IwUPiYyE0n3j4U-yEvMpCWxKqXv9e361XxhfArzmaROxBeGeaPb7ZrSBnlGv6K28GJLqBxf_NlglKU8DVSlLHCcMMY-TtvNEzfNXmhnxFAltZ45i5GhksXM0ZDmy31ISpSY",
  //   // },
  //   // {
  //   //   name: "David",
  //   //   certification: "Done Certified Pro",
  //   //   rating: 1012,
  //   //   isRated: false,
  //   //   likes: 100,
  //   //   communication_skills: [
  //   //     "Knowledgeable",
  //   //     "Friendly",
  //   //     "Efficient & Quick",
  //   //     "Good quality",
  //   //   ],
  //   //   customer_joining_date: "Dec 2020 Oakville, ON",
  //   //   review_description:
  //   //     "David arrived on time with all the tools needed for the job. He was personable and knowledgeable. We would absolutely use David’s services again.",
  //   //   img: "https://themeearth.com/tf/html/autowash/img/tm.jpg",
  //   // },
  //   // {
  //   //   name: "Sophia",
  //   //   certification: "Done Certified Pro",
  //   //   rating: 1020,
  //   //   isRated: true,
  //   //   likes: 108,
  //   //   communication_skills: ["Good quality", "Efficient & Quick", "Friendly"],
  //   //   customer_joining_date: "Oct 2019 Toronto, ON",
  //   //   review_description:
  //   //     "Sophia was prompt and professional. The quality of her work was excellent. Highly recommend her.",
  //   //   img: "https://avatars.mds.yandex.net/i?id=71328d4499cb6896face86dcf82f481a3bcc7792-8549383-images-thumbs&n=13",
  //   // },
  // ];

  // Use refs to target custom navigation buttons

  return (
    get_service_by_id_data?.reviews?.length > 0 && (
      <Stack
        sx={{
          paddingX: { xl: 3, lg: 3, md: 3, sm: 0, xs: 0 },
          backgroundColor: "white",
        }}
      >
        <Typography mt={3} className="service-sub-name">
          Discover our qualified and trusted Done Pros
        </Typography>
        <Typography mt={3} className="reviews_heading_txt">
          All Done Pros are thoroughly insured, carefully vetted, and fully
          qualified. Each pro is highly rated by our customers and must maintain
          at least a{" "}
          <Typography
            variant="caption"
            className="number_family"
            sx={{ fontWeight: "700", fontSize: "15px" }}
          >
            90%
          </Typography>{" "}
          approval rating to continue offering their services. When you book
          with us, you'll be matched with a top-rated pro.
        </Typography>

        <Typography my={2}>
          Here’s a{" "}
          <Typography
            variant="caption"
            sx={{ fontWeight: "700", fontSize: "15px" }}
          >
            sample of some of the skilled professionals
          </Typography>{" "}
          you could be connected with:
        </Typography>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <IconButton
            sx={{
              padding: {
                xl: "8px",
                lg: "8px",
                md: "8px",
                sm: "8px",
                xs: "0px",
              },
              fontSize: {
                xl: "30px",
                lg: "30px",
                md: "30px",
                sm: "30px",
                xs: "20px",
              },
            }}
            ref={prevRef}
            className="swiper-button-prev-custom"
          >
            <IoIosArrowBack style={{ color: themeOrange }} />
          </IconButton>

          <Swiper
            slidesPerView={2} // Adjust the number of visible slides
            pagination={{
              clickable: false, // Enable pagination bullets
            }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }} // Enable navigation arrows
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            modules={[Navigation]} // Use Swiper modules
            className="mySwiper"
            breakpoints={{
              1200: {
                slidesPerView: 2, // Show 2 slides for xl (>= 1200px)
              },
              1024: {
                slidesPerView: 1, // Show 2 slides for lg (>= 1024px)
              },
              768: {
                slidesPerView: 1, // Show 1 slide for md (>= 768px)
              },
              600: {
                slidesPerView: 1, // Show 1 slide for sm (>= 600px)
              },
              0: {
                slidesPerView: 1, // Show 1 slide for xs (below 600px)
              },
            }}
          >
            {get_service_by_id_data?.reviews?.map(
              ({ msg, rating, user, vendor }, i) => {
                const isExpanded = expandedReviews[i] || false;
                return (
                  <SwiperSlide key={i}>
                    <Grid
                      container
                      alignItems={"stretch"}
                      className="review_wrapper"
                      my={3}
                      mx={{ xl: 2, lg: 2, md: 2, sm: 0, xs: 0 }}
                    >
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ pr: { xl: 6, lg: 6, md: 6, sm: 0, xs: 0 } }}
                      >
                        <Stack
                          direction={{
                            xl: "row",
                            lg: "row",
                            md: "column",
                            sm: "row",
                            xs: "column",
                          }}
                          alignItems={{
                            xl: "flex-start",
                            lg: "flex-start",
                            md: "center",
                            sm: "flex-start",
                            xs: "center",
                          }}
                          justifyContent={"center"}
                          gap={2}
                        >
                          <Avatar
                            src={`${imgUrl}${vendor?.avatar || ""}`}
                            sx={{ height: "90px", width: "90px" }}
                          />
                          <Stack
                            gap={0.5}
                            alignItems={{
                              xl: "flex-start",
                              lg: "flex-start",
                              md: "center",
                              sm: "flex-start",
                              xs: "center",
                            }}
                          >
                            <Typography
                              className="review_vendor_name"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {vendor?.first_name || ""}{" "}
                              {vendor?.last_name || ""}
                            </Typography>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"flex-start"}
                              columnGap={1}
                              flexWrap={"nowrap"}
                              my={0.4}
                            >
                              <SlBadge
                                size={20}
                                style={{ color: "black", flexShrink: "0" }}
                              />
                              <Typography className="review_vendor_certification">
                                {/* {certification || ""} */}
                                Done Certified Pro
                              </Typography>
                              <BsQuestionCircleFill
                                size={20}
                                style={{ color: "grey", flexShrink: "0" }}
                              />
                            </Stack>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"flex-start"}
                              flexWrap={"nowrap"}
                              gap={1}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                flexWrap={"nowrap"}
                                gap={1}
                                sx={{
                                  backgroundColor: themeBlue,
                                  borderRadius: "5px",
                                  py: 0.5,
                                  px: 1,
                                }}
                              >
                                <AiFillLike
                                  size={15}
                                  style={{ color: "white", flexShrink: "0" }}
                                />
                                <Typography className="review_vendor_like_percant number_family">
                                  {/* {likes.toLocaleString()} % */}
                                  10 %
                                </Typography>
                              </Stack>

                              <Typography
                                className="review_vendor_certification"
                                sx={{ color: "#4a4a4a !important" }}
                              >
                                of{" "}
                                <Typography
                                  variant="caption"
                                  className="review_vendor_certification number_family"
                                  sx={{ color: "#4a4a4a !important" }}
                                >
                                  {(+rating)?.toLocaleString() || ""}
                                </Typography>{" "}
                                ratings
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>

                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={2}
                          my={2}
                        >
                          <Typography className="review_vendor_feedback_title">
                            {user?.first_name || ""} {user?.last_name || ""} top
                            customer feedback:
                          </Typography>

                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap={2}
                            flexWrap={"wrap"}
                          >
                            {["Friendly", "Patient", "Good quality"].map(
                              (data) => {
                                return (
                                  <Typography className="review_vendor_labels">
                                    {data || ""}
                                  </Typography>
                                );
                              }
                            )}
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Stack
                          justifyContent={"space-between"}
                          sx={{ height: "100%" }}
                          gap={3}
                        >
                          <Stack>
                            <Typography className="review_vendor_recent_review_txt">
                              Recent Review:{" "}
                              <Typography
                                variant="caption"
                                className="review_vendor_recent_review_description"
                              >
                                {_handleReviewText(msg, isExpanded) || ""}{" "}
                                <Typography
                                  onClick={() => _toggleReviewExpansion(i)}
                                  variant="caption"
                                  className="review_vendor_recent_review_description"
                                  sx={{
                                    ":hover": {
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    },
                                    color: `${themeOrange} !important`,
                                    fontSize: "14px",
                                  }}
                                >
                                  {isExpanded ? "See Less" : "See More"}
                                </Typography>
                              </Typography>
                            </Typography>
                          </Stack>
                          <Stack
                            alignItems={"flex-start"}
                            justifyContent={"center"}
                            gap={1}
                            mb={2}
                          >
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              gap={2}
                              sx={{ width: "100%" }}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={1}
                              >
                                <IoIosCheckmarkCircle
                                  size={20}
                                  style={{ flexShrink: "0", color: "grey" }}
                                />
                                <Typography
                                  variant="caption"
                                  className="review_vendor_rate_verfied_txt"
                                  sx={{
                                    color: `${themeBlue} !important`,
                                    fontWeight: "500 !important",
                                  }}
                                >
                                  Verified review
                                </Typography>
                              </Stack>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={1}
                              >
                                <Typography
                                  variant="caption"
                                  className="review_vendor_rate_verfied_txt"
                                >
                                  Rated{" "}
                                </Typography>
                                {true ? (
                                  <AiFillLike
                                    size={15}
                                    style={{
                                      flexShrink: "0",
                                      color: themeBlue,
                                    }}
                                  />
                                ) : (
                                  <AiFillDislike
                                    size={15}
                                    style={{
                                      flexShrink: "0",
                                      color: themeBlue,
                                    }}
                                  />
                                )}
                              </Stack>
                            </Stack>
                            <Typography
                              variant="caption"
                              className="review_vendor_rate_verfied_txt"
                            >
                              {/* Customer joined {customer_joining_date || ""} */}
                              Customer joined Feb 2021 Toronto, ON
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>

          <IconButton
            sx={{
              padding: {
                xl: "8px",
                lg: "8px",
                md: "8px",
                sm: "8px",
                xs: "0px",
              },
              fontSize: {
                xl: "30px",
                lg: "30px",
                md: "30px",
                sm: "30px",
                xs: "20px",
              },
            }}
            ref={nextRef}
            className="swiper-button-next-custom"
          >
            <IoIosArrowForward style={{ color: themeOrange }} />
          </IconButton>
        </Stack>
      </Stack>
    )
  );
};

export default VendorReviews;
