import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaCalendarDays, FaLocationDot } from "react-icons/fa6";
import { MdDescription } from "react-icons/md";
import ButtonComp from "../../component/common/ButtonComp";
import { themeBlue, themeGray, themeOrange } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { acceptJobAsync, getJobsAsync } from "../../services/services";
import { asyncStatus } from "../../utils/asyncStatus";
import { imgUrl } from "../../utils/imageUrl";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import { inputDateFormate } from "../../utils/CustomFormator";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Import the marker icon and shadow images from Leaflet
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";

// Set the default icon for markers
const customMarkerIcon = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41], // size of the shadow
});

const JobRequest = () => {
  const dispatch = useDispatch();
  const [seeDes, setSeeDes] = useState(false);
  const [detailInd, setDetailInd] = useState();
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [positions, setPositions] = useState({});

  const { get_jobs_status, get_jobs_data, accept_job_status } = useSelector(
    (state) => state.serviceManage
  );
  const { user } = useSelector((state) => state.userAuth);

  const getJobsLoader = get_jobs_status === asyncStatus.LOADING;

  const apiKey = "AIzaSyCG3Xlad4kf39CqRO9FWw01sIJiixrCx_M";

  useEffect(() => {
    const fetchCoordinates = async (location, jobId) => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${apiKey}`
        );
        const { lat, lng } = response.data.results[0].geometry.location;
        setPositions((prevPositions) => ({
          ...prevPositions,
          [jobId]: [lat, lng],
        }));
      } catch (error) {
        console.error("Geocoding error:", error);
      }
    };

    if (get_jobs_data?.length) {
      get_jobs_data.forEach((job) => {
        if (job.location) {
          fetchCoordinates(job.location, job.id);
        }
      });
    }
  }, [get_jobs_data, apiKey]);

  const _handleMapClick = (position) => {
    if (position) {
      const googleMapsUrl = `https://www.google.com/maps?q=${position[0]},${position[1]}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  useEffect(() => {
    dispatch(getJobsAsync());
  }, []);

  const _handleViewDetail = (id) => {
    setDetailInd(id);
  };

  const _handleHideDetail = (id) => {
    setDetailInd();
  };

  const _handleLocationLength = (desc) => {
    const des = desc.slice(0, 34);
    return des.length > 35 ? (seeDes ? des : `${des}...`) : des;
  };

  const handleAccept = (elm) => {
    setSelectedJobId(elm);
    dispatch(acceptJobAsync({ order_id: elm }));
  };

  useEffect(() => {
    if (accept_job_status === asyncStatus.ERROR) {
      error_toast_message("Job already accepted by another vendor");
    } else if (accept_job_status === asyncStatus.SUCCEEDED) {
      success_toast_message("Job accept successfully");
    }

    // Reload the window after showing the toast message
    if (
      accept_job_status === asyncStatus.ERROR ||
      accept_job_status === asyncStatus.SUCCEEDED
    ) {
      window.location.reload();
    }
  }, [accept_job_status]);

  const userStatus = user?.status === 1;

  return (
    <Stack bgcolor={"#FAFAFA"}>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Stack gap={3}>
          <Stack>
            <Typography className="mainHeading">Job Request</Typography>
            <hr color={"#D1D1D1"} />
          </Stack>
          {getJobsLoader ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "60vh", width: "100%" }}
            >
              <CircularProgress size={30} sx={{ color: themeOrange }} />
            </Stack>
          ) : get_jobs_data?.length > 0 ? (
            get_jobs_data?.map(
              ({
                date,
                description,
                id,
                job_end_request,
                location,
                payer_id,
                service_id,
                status,
                time,
                user_id,
                created_at,
                vendor_id,
                user,
                service,
                images,
              }) => {
                const position = positions[id];
                return (
                  <Grid container spacing={2} alignItems={"stretch"}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={detailInd === id && 8}
                      lg={detailInd === id && 8}
                      xl={detailInd === id && 8}
                    >
                      <Stack sx={{ height: "100%" }}>
                        <Stack
                          bgcolor={"white"}
                          mt={2}
                          borderRadius={4}
                          p={2}
                          sx={{ height: "100%" }}
                        >
                          <Stack
                            flexDirection={"row"}
                            // alignItems={"center"}
                            gap={2}
                            my={1}
                            px={2}
                          >
                            <img
                              width={"99px"}
                              style={{
                                width: "99px",
                                height: "99px",
                                objectFit: "contain",
                                borderRadius: "10px",
                                backgroundColor: "lightgrey",
                              }}
                              src={`${imgUrl}${user?.avatar}`}
                            />
                            <Stack gap={1} justifyContent={"center"}>
                              <Typography className="subHeading">
                                {`${user?.first_name || ""} ${
                                  user?.last_name || ""
                                }`}
                              </Typography>
                              <Typography className="subPara">
                                {user?.email || ""}
                              </Typography>
                              {user?.phone && (
                                <Typography
                                  className="subPara"
                                  sx={{ color: "red" }}
                                >
                                  {user?.phone || ""}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Stack px={2} my={1}>
                            <hr />
                          </Stack>

                          <Stack gap={4} p={2}>
                            <Typography className="mainHeading">
                              {service?.name ?? ""}
                            </Typography>
                            <Stack
                              direction={{
                                xl: "row",
                                lg: "row",
                                md: "row",
                                sm: "column",
                                xs: "column",
                              }}
                              // justifyContent={"space-between"}
                              // sx={{backgroundColor:"red"}}
                              gap={2}
                            >
                              <Stack
                                direction={"row"}
                                justifyContent={{
                                  xl: "center",
                                  lg: "center",
                                  md: "center",
                                  sm: "flex-start",
                                  xs: "flex-start",
                                }}
                                gap={2}
                                sx={{ width: "205px" }}
                                alignItems={"flex-start"}
                              >
                                <FaCalendarDays
                                  size={25}
                                  style={{ color: themeOrange, flexShrink: 0 }}
                                />
                                <Typography
                                  className="subPara"
                                  variant="caption"
                                  sx={{ flexShrink: 0 }}
                                >
                                  {inputDateFormate(date || "") || ""}
                                </Typography>
                              </Stack>
                              <Stack
                                direction={"row"}
                                justifyContent={{
                                  xl: "center",
                                  lg: "center",
                                  md: "center",
                                  sm: "flex-start",
                                  xs: "flex-start",
                                }}
                                gap={2}
                                alignItems={"flex-start"}
                                sx={{ width: "205px" }}
                              >
                                <FaLocationDot
                                  size={25}
                                  style={{ color: themeOrange, flexShrink: 0 }}
                                />
                                <Typography
                                  className="subPara"
                                  variant="caption"
                                  sx={{
                                    flexShrink: "inherit",
                                    cursor: "pointer",
                                  }}
                                  // onClick={() => setSeeDes(!seeDes)}
                                >
                                  {_handleLocationLength(location) ?? ""}
                                  {/* <Typography
                                    variant="caption"
                                    sx={{
                                      color: themeOrange,
                                      ml: "1px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    {!seeDes ? "see more" : "see less"}
                                  </Typography> */}
                                </Typography>
                              </Stack>
                              <Stack
                                direction={"row"}
                                justifyContent={{
                                  xl: "center",
                                  lg: "center",
                                  md: "center",
                                  sm: "flex-start",
                                  xs: "flex-start",
                                }}
                                gap={2}
                                alignItems={"flex-start"}
                                sx={{ flex: 1 }}
                              >
                                <MdDescription
                                  size={25}
                                  style={{ color: themeOrange, flexShrink: 0 }}
                                />

                                <Typography
                                  className="subPara"
                                  variant="caption"
                                  // onClick={() => setSeeDes(!seeDes)}
                                >
                                  {_handleLocationLength(description) ?? ""}

                                  {/* <Typography
                                    variant="caption"
                                    sx={{
                                      color: themeOrange,
                                      ml: "1px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    {!seeDes ? "see more" : "see less"}
                                  </Typography> */}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack
                              direction={"row"}
                              width={1}
                              px={6}
                              // alignItems={"center"}
                              // justifyContent={"center"}
                            >
                              <Stack
                                p={2}
                                bgcolor={"#FAFAFA"}
                                border={"0.5px solid grey"}
                                borderRadius={3}
                              >
                                <Typography className="mainPara">
                                  {description ?? ""}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              px={5}
                            >
                              <Stack>
                                {detailInd === id ? (
                                  <ButtonComp
                                    onClick={_handleHideDetail}
                                    style={{
                                      backgroundColor: "transparent",
                                      color: themeOrange,
                                      border: "1px solid #F15A24",
                                      padding: "10px 30px",
                                      borderRadius: "10px",
                                    }}
                                    label={"Hide Details"}
                                  />
                                ) : (
                                  <ButtonComp
                                    onClick={() => _handleViewDetail(id)}
                                    style={{
                                      backgroundColor: "transparent",
                                      color: themeOrange,
                                      border: "1px solid #F15A24",
                                      padding: "10px 30px",
                                      borderRadius: "10px",
                                    }}
                                    label={"View Details"}
                                  />
                                )}
                              </Stack>
                              {
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={2}
                                >
                                  {userStatus && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#52C24A",
                                        height: "45px",
                                        width: "auto",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        ":hover": {
                                          backgroundColor: "#52C24A",
                                        },
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px 30px",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={() => handleAccept(id)}
                                    >
                                      {accept_job_status ===
                                        asyncStatus.LOADING &&
                                      selectedJobId === id ? (
                                        <CircularProgress
                                          size={15}
                                          sx={{ color: "white" }}
                                        />
                                      ) : (
                                        "Accept"
                                      )}
                                    </Button>
                                  )}
                                </Stack>
                              }
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                    {detailInd === id && (
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Stack
                          mt={2}
                          sx={{
                            borderRadius: "16px",
                            backgroundColor: "white",
                            height: "488px",
                            overflow: "auto",
                          }}
                        >
                          <Stack
                            className="globleGradientBlue"
                            p={2}
                            borderRadius={"10px 10px 0px 0px"}
                          >
                            <Typography className="subHeading" color={"white"}>
                              {service?.name || ""}
                            </Typography>
                          </Stack>
                          <Stack mt={4} gap={3} p={2}>
                            <Stack
                              direction={"row"}
                              justifyContent={"start"}
                              gap={2}
                              alignItems={"center"}
                            >
                              <FaCalendarDays
                                size={25}
                                style={{ color: themeBlue, flexShrink: 0 }}
                              />
                              <Typography className="subPara">
                                {inputDateFormate(date) || ""}
                              </Typography>
                            </Stack>

                            <Stack
                              direction={"row"}
                              justifyContent={"start"}
                              gap={2}
                            >
                              <MdDescription
                                size={25}
                                style={{ color: themeBlue, flexShrink: 0 }}
                              />
                              <Typography className="subPara">
                                {description ?? ""}
                              </Typography>
                            </Stack>
                            {location && (
                              <Stack
                                direction={"row"}
                                justifyContent={"start"}
                                gap={2}
                                alignItems={"center"}
                              >
                                <FaLocationDot
                                  size={25}
                                  style={{ color: themeBlue, flexShrink: 0 }}
                                />
                                <Typography className="subPara">
                                  {location || ""}
                                </Typography>
                              </Stack>
                            )}
                            <div
                              style={{
                                height: "162px",
                                width: "100%",
                                borderRadius: "20px",
                                overflow: "hidden",
                              }}
                            >
                              {position ? (
                                <MapContainer
                                  center={position}
                                  zoom={13}
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                  />
                                  <Marker
                                    icon={customMarkerIcon}
                                    eventHandlers={{
                                      click: () => _handleMapClick(position),
                                    }}
                                    position={position}
                                  >
                                    <Popup>{location}</Popup>
                                  </Marker>
                                </MapContainer>
                              ) : (
                                <p>Loading map...</p>
                              )}
                            </div>
                            <Stack gap={1}>
                              <Typography className="secondSubHeading">
                                Job Description
                              </Typography>
                              <Typography
                                className="mainPara"
                                color={"grey"}
                                sx={{
                                  border: "0.6px solid #F1F1F1",
                                  padding: "10px",
                                  borderRadius: "20px",
                                }}
                              >
                                {description || ""}
                              </Typography>
                            </Stack>

                            <Stack gap={1}>
                              <Typography className="secondSubHeading">
                                Timing constraints
                              </Typography>
                              <Typography
                                className="mainPara"
                                color={"grey"}
                                sx={{
                                  border: "0.6px solid #F1F1F1",
                                  padding: "10px",
                                  borderRadius: "20px",
                                }}
                              >
                                {time || ""}
                              </Typography>
                            </Stack>

                            <Stack sx={{ width: "100%" }}>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={1}
                                sx={{ overflow: "auto", width: "100%" }}
                              >
                                {images?.map(({ image }) => {
                                  return (
                                    <Stack>
                                      <img
                                        width={"80px"}
                                        src={`${imgUrl}${image}`}
                                      />
                                    </Stack>
                                  );
                                })}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                );
              }
            )
          ) : (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "30vh", width: "100%" }}
            >
              <Typography className="secondSubHeading">
                No Jobs found
              </Typography>
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default JobRequest;
