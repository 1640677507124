import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  createTheme,
  Box,
  ThemeProvider,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import { BiSolidCalendar } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { MdWatchLater } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllJobsAsync,
  PostReviewAsync,
  updateJobStatusAsync,
} from "../../services/services";
import { imgUrl } from "../../utils/imageUrl";
import Input from "../../component/common/Input";
import ReactStars from "react-rating-stars-component";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import { inputDateFormate } from "../../utils/CustomFormator";
import { HiMiniChatBubbleLeftRight } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const MyJobs = () => {
  const [viewJobDetail, setViewJobDetail] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState("");
  const [jobReview, setjobReview] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    get_all_jobs_status,
    get_all_jobs_error,
    get_all_jobs_data,
    post_review_status,
    post_review_error,
    post_review_data,
    job_status_update_status,
  } = useSelector((state) => state.serviceManage);

  useEffect(() => {
    dispatch(GetAllJobsAsync());
  }, []);

  const jobLoader = get_all_jobs_status === asyncStatus.LOADING;
  const jobStatusLoader = job_status_update_status === asyncStatus.LOADING;

  const handleViewDetails = (jobId) => {
    setSelectedJobId((prevId) => (prevId === jobId ? null : jobId));
  };

  const handleCancel = (elm, status) => {
    let obj = {
      status: status,
      id: elm,
    };
    setSelectedJobStatus(status);
    dispatch(updateJobStatusAsync(obj));
  };

  const jobs = get_all_jobs_data || [];

  const inQueueJobsCount = jobs.filter(
    (job) => job.status === "In-Queue"
  ).length;
  const assignedJobsCount = jobs.filter(
    (job) => job.status === "Assigned"
  ).length;
  const completedJobsCount = jobs.filter(
    (job) => job.status === "Completed"
  ).length;

  // const cancelledJobsCount = jobs.filter((job) => job.status !== "Cancelled").length;

  const ratingChanged = (newRating) => {
    setjobReview({ ...jobReview, rating: newRating });
  };

  const handlePostReview = (elm) => {
    let obj = {
      msg: jobReview.msg,
      rating: jobReview.rating,
      oid: elm,
    };
    dispatch(PostReviewAsync(obj));

    setjobReview({
      msg: "",
      rating: "",
    });
  };

  const _handleVanderChat = (_id, first_name, last_name, avatar) => {
    navigate(`/dashboard/message-user`, {
      state: {
        id: _id,
        first_name: first_name,
        last_name: last_name,
        avatar: avatar,
      },
    });
  };

  const activeJobs = get_all_jobs_data?.filter(
    (job) => job?.status !== "Cancelled" && job?.status !== "Completed"
  );
  const completedJobs = get_all_jobs_data?.filter(
    (job) => job?.status === "Completed"
  );

  return (
    <Stack bgcolor={"#FAFAFA"}>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Stack gap={1} pb={2}>
          <Stack>
            <Typography className="mainHeading">My Jobs</Typography>
            <hr color={"#D1D1D1"} />
          </Stack>
          {jobLoader ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "60vh", width: "100%" }}
            >
              <CircularProgress size={30} sx={{ color: themeOrange }} />
            </Stack>
          ) : get_all_jobs_data?.length === 0 ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "60vh", width: "100%" }}
            >
              <Typography>No Jobs Found</Typography>
            </Stack>
          ) : (
            <Stack>
              {/* <!------ Active Jobs ------!>  */}
              {activeJobs?.length > 0 && (
                <Stack>
                  <Typography className="secondSubHeading" sx={{ mt: 2 }}>
                    Active Jobs ({inQueueJobsCount + assignedJobsCount})
                  </Typography>

                  <Stack>
                    {get_all_jobs_data?.map((e, i) => {
                      const isSelected = selectedJobId === e?.id;
                      if (
                        e?.status != "Cancelled" &&
                        e?.status != "Completed"
                      ) {
                        console.log("e", e);
                        const txt = `In publishing and graphic design, Lorem ipsum is
                                a placeholder text commonly used to demonstrate
                                the visual form of a document or a typeface
                                without relying on meaningful content`;
                        return (
                          <Stack
                            key={e?.id}
                            sx={{
                              width: "100%",
                              backgroundColor: "white",
                              borderRadius: "15px",
                            }}
                          >
                            <Stack
                              className="globleGradientBlue"
                              sx={{
                                borderRadius: "15px",
                                py: 1.5,
                                px: 2,
                                boxShadow: "none",
                              }}
                            >
                              <Typography
                                className="secondSubHeading"
                                sx={{ color: "white" }}
                              >
                                {e?.service?.name || ""}
                              </Typography>
                            </Stack>

                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              sx={{ px: 2, py: 2 }}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"flex-start"}
                                gap={2}
                                sx={{ width: "100%" }}
                              >
                                <Stack
                                  pb={1.5}
                                  gap={1}
                                  sx={{
                                    borderRadius: "10px",
                                    width: "150px",
                                    backgroundColor: "#F5F5F5",
                                    flexShrink: "0",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Stack sx={{ height: "70px", width: "100%" }}>
                                    <img
                                      src={`${imgUrl}${e?.service?.image}`}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Stack>
                                  <Typography
                                    className="mainPara"
                                    sx={{
                                      color: "#333333",
                                      mx: 1,
                                      textAlign: "center",
                                    }}
                                  >
                                    {e?.service?.name || ""}
                                  </Typography>
                                </Stack>

                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  sx={{ width: "100%" }}
                                  flexWrap={{
                                    xl: "nowrap",
                                    lg: "nowrap",
                                    md: "wrap",
                                    sm: "wrap",
                                    xs: "wrap",
                                  }}
                                >
                                  <Stack gap={0.5}>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"flex-start"}
                                      justifyContent={"flex-start"}
                                      gap={1}
                                    >
                                      <BiSolidCalendar
                                        style={{
                                          fontSize: "24px",
                                          color: "#F15A24",
                                          flexShrink: 0,
                                        }}
                                      />
                                      <Typography
                                        className="subpara"
                                        sx={{ color: "#333333" }}
                                      >
                                        {inputDateFormate(e?.date || "")}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"flex-start"}
                                      justifyContent={"flex-start"}
                                      gap={1}
                                    >
                                      <MdWatchLater
                                        style={{
                                          fontSize: "24px",
                                          color: "#F15A24",
                                          flexShrink: 0,
                                        }}
                                      />
                                      <Typography
                                        className="subpara"
                                        sx={{ color: "#333333" }}
                                      >
                                        {e?.time?.length > 30
                                          ? `${e?.time?.substring(0, 30)}...`
                                          : e?.time}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"flex-start"}
                                      justifyContent={"flex-start"}
                                      gap={1}
                                    >
                                      <MdLocationOn
                                        style={{
                                          fontSize: "24px",
                                          color: "#F15A24",
                                          flexShrink: 0,
                                        }}
                                      />
                                      <Typography
                                        className="subpara"
                                        sx={{ color: "#333333" }}
                                      >
                                        {e?.location || ""}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      borderColor: "#F15A24",
                                      color: "#F15A24",
                                      borderRadius: "15px",
                                      px: 3,
                                      py: 1,
                                      backgroundColor: "white",
                                      ":hover": {
                                        backgroundColor: "white",
                                        borderColor: "#F15A24",
                                      },
                                      textTransform: "none",
                                      mt: 1,
                                    }}
                                    onClick={() => handleViewDetails(e?.id)}
                                  >
                                    {isSelected ? "Hide Detail" : "View Detail"}
                                  </Button>
                                </Stack>
                              </Stack>
                            </Stack>

                            {isSelected && (
                              <Stack
                                sx={{
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderRadius: "15px",
                                  px: 1,
                                }}
                              >
                                {/* Job Description */}
                                <Stack gap={1} px={2} py={2}>
                                  <Typography
                                    className="mainHeading"
                                    sx={{ color: "#333333" }}
                                  >
                                    Job Description
                                  </Typography>
                                  <Stack
                                    sx={{
                                      borderRadius: "15px",
                                      border: "1px solid #F1F1F1",
                                    }}
                                    px={2}
                                    py={2}
                                  >
                                    <Typography
                                      className="mainPara"
                                      sx={{ color: "#868181" }}
                                    >
                                      {e?.description || ""}
                                    </Typography>
                                  </Stack>
                                </Stack>

                                {/* Timing Constraints */}
                                <Stack gap={1} px={2} py={2}>
                                  <Typography
                                    className="mainHeading"
                                    sx={{ color: "#333333" }}
                                  >
                                    Timing Constraints
                                  </Typography>
                                  <Stack
                                    sx={{
                                      borderRadius: "15px",
                                      border: "1px solid #F1F1F1",
                                    }}
                                    px={2}
                                    py={2}
                                  >
                                    <Typography
                                      className="mainPara"
                                      sx={{
                                        color: "#868181",
                                        textAlign: "start",
                                      }}
                                    >
                                      {e?.time || ""}
                                    </Typography>
                                  </Stack>
                                </Stack>

                                {e?.vendor?.first_name && (
                                  <Stack gap={1} px={2} py={2}>
                                    <Typography
                                      className="mainHeading"
                                      sx={{ color: "#333333" }}
                                    >
                                      Vendor Detail
                                    </Typography>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                      sx={{
                                        borderRadius: "15px",
                                        border: "1px solid #F1F1F1",
                                      }}
                                      px={2}
                                      py={2}
                                    >
                                      <Stack>
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          gap={1}
                                        >
                                          <Typography
                                            className="mainPara"
                                            sx={{
                                              color: "black",
                                              fontWeight: "600 !important",
                                              fontSize: "16px !important",
                                            }}
                                          >
                                            Name :
                                          </Typography>
                                          <Typography
                                            className="mainPara"
                                            sx={{ color: "#868181" }}
                                          >
                                            {`${e?.vendor?.first_name || ""} ${
                                              e?.vendor?.last_name || ""
                                            }`}
                                          </Typography>
                                        </Stack>
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          gap={1}
                                        >
                                          <Typography
                                            className="mainPara"
                                            sx={{
                                              color: "black",
                                              fontWeight: "600 !important",
                                              fontSize: "16px !important",
                                            }}
                                          >
                                            Email :
                                          </Typography>
                                          <Typography
                                            // className="mainPara"
                                            sx={{ color: "#868181" }}
                                          >
                                            {`${e?.vendor?.email || ""} `}
                                          </Typography>
                                        </Stack>
                                      </Stack>

                                      <Button
                                        variant="contained"
                                        startIcon={
                                          <HiMiniChatBubbleLeftRight
                                            size={17}
                                            sx={{ color: "white" }}
                                          />
                                        }
                                        sx={{
                                          borderColor: "#F15A24",
                                          borderRadius: "15px",
                                          px: 3,
                                          py: 1,
                                          color: "white",
                                          backgroundColor: "#F15A24",
                                          ":hover": {
                                            backgroundColor: "#F15A24",
                                            borderColor: "#F15A24",
                                          },
                                          mx: 2,
                                          my: 2,
                                        }}
                                        onClick={() =>
                                          _handleVanderChat(
                                            e?.vendor?.id || "",
                                            e?.vendor?.first_name || "",
                                            e?.vendor?.last_name || "",
                                            e?.vendor?.avatar || ""
                                          )
                                        }
                                      >
                                        Chat Now
                                      </Button>
                                    </Stack>
                                  </Stack>
                                )}

                                {/* Multiple Images */}
                                <Stack
                                  gap={1}
                                  className="jobs_images_scrollbar_wrapper"
                                  direction={"row"}
                                  alignItems={"center"}
                                  sx={{
                                    mx: 2,
                                  }}
                                >
                                  {e?.images?.map((image, i) => (
                                    <img
                                      key={i}
                                      src={`${imgUrl}${image?.image}`}
                                      style={{
                                        height: "105px",
                                        width: "105px",
                                        borderRadius: "15px",
                                      }}
                                    />
                                  ))}
                                </Stack>

                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-around"}
                                >
                                  {e?.vendor && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        borderColor: "#F15A24",
                                        borderRadius: "15px",
                                        px: 3,
                                        py: 1,
                                        color: "white",
                                        backgroundColor: "#F15A24",
                                        ":hover": {
                                          backgroundColor: "#F15A24",
                                          borderColor: "#F15A24",
                                        },
                                        mx: 2,
                                        my: 2,
                                        width: "170px",
                                      }}
                                      onClick={() =>
                                        handleCancel(e?.id, "Completed")
                                      }
                                    >
                                      {jobStatusLoader &&
                                      selectedJobStatus === "Completed" ? (
                                        <CircularProgress
                                          size={20}
                                          sx={{ color: "white" }}
                                        />
                                      ) : (
                                        "COMPLETE JOB"
                                      )}
                                    </Button>
                                  )}
                                  <Button
                                    variant="contained"
                                    sx={{
                                      borderColor: "#F15A24",
                                      borderRadius: "15px",
                                      px: 3,
                                      py: 1,
                                      color: "white",
                                      backgroundColor: "#F15A24",
                                      ":hover": {
                                        backgroundColor: "#F15A24",
                                        borderColor: "#F15A24",
                                      },
                                      mx: 2,
                                      my: 2,
                                      width: "170px",
                                    }}
                                    onClick={() =>
                                      handleCancel(e?.id, "Cancelled")
                                    }
                                  >
                                    {jobStatusLoader &&
                                    selectedJobStatus === "Cancelled" ? (
                                      <CircularProgress
                                        size={20}
                                        sx={{ color: "white" }}
                                      />
                                    ) : (
                                      "CANCEL JOB"
                                    )}
                                  </Button>
                                </Stack>
                              </Stack>
                            )}
                          </Stack>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </Stack>
              )}

              {/* <!------ Completed Jobs ------!>  */}
              {completedJobs?.length > 0 && (
                <Stack>
                  <Typography className="secondSubHeading" mt={5}>
                    Completed Jobs ({completedJobsCount})
                  </Typography>

                  <Stack
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "15px",
                      height: "200px",
                    }}
                  >
                    {completedJobs?.map((e, i) => {
                      const isSelected = selectedJobId === e?.id; // Assume `e.id` is the unique identifier for the job
                      if (e?.status === "Completed") {
                        return (
                          <Stack
                            key={e?.id}
                            sx={{
                              width: "100%",
                              backgroundColor: "white",
                              borderRadius: "15px",
                            }}
                          >
                            <Stack
                              className="globleGradientBlue"
                              sx={{
                                borderRadius: "15px",
                                py: 1.5,
                                px: 2,
                                boxShadow: "none",
                              }}
                            >
                              <Typography
                                className="secondSubHeading"
                                sx={{ color: "white" }}
                              >
                                {e?.service?.name || ""}
                              </Typography>
                            </Stack>

                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              sx={{ px: 2, py: 2 }}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"flex-start"}
                                gap={2}
                                sx={{ width: "100%" }}
                              >
                                <Stack
                                  pb={1.5}
                                  gap={1}
                                  sx={{
                                    borderRadius: "10px",
                                    width: "150px",
                                    backgroundColor: "#F5F5F5",
                                    flexShrink: "0",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Stack sx={{ height: "70px", width: "100%" }}>
                                    <img
                                      src={`${imgUrl}${e?.service?.image}`}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Stack>
                                  <Typography
                                    className="mainPara"
                                    sx={{
                                      color: "#333333",
                                      mx: 1,
                                      textAlign: "center",
                                    }}
                                  >
                                    {e?.service?.name || ""}
                                  </Typography>
                                </Stack>

                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  sx={{ width: "100%" }}
                                  flexWrap={{
                                    xl: "nowrap",
                                    lg: "nowrap",
                                    md: "wrap",
                                    sm: "wrap",
                                    xs: "wrap",
                                  }}
                                >
                                  <Stack gap={0.5}>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"flex-start"}
                                      justifyContent={"flex-start"}
                                      gap={1}
                                    >
                                      <BiSolidCalendar
                                        style={{
                                          fontSize: "24px",
                                          color: "#F15A24",
                                          flexShrink: 0,
                                        }}
                                      />
                                      <Typography
                                        className="subpara"
                                        sx={{ color: "#333333" }}
                                      >
                                        {inputDateFormate(e?.date || "")}
                                      </Typography>
                                    </Stack>

                                    <Stack
                                      direction={"row"}
                                      alignItems={"flex-start"}
                                      justifyContent={"flex-start"}
                                      gap={1}
                                    >
                                      <MdWatchLater
                                        style={{
                                          fontSize: "24px",
                                          color: "#F15A24",
                                          flexShrink: 0,
                                        }}
                                      />
                                      <Typography
                                        className="subpara"
                                        sx={{ color: "#333333" }}
                                      >
                                        {e?.time?.length > 30
                                          ? `${e?.time?.substring(0, 30)}...`
                                          : e?.time}
                                      </Typography>
                                    </Stack>

                                    <Stack
                                      direction={"row"}
                                      alignItems={"flex-start"}
                                      justifyContent={"flex-start"}
                                      gap={1}
                                    >
                                      <MdLocationOn
                                        style={{
                                          fontSize: "24px",
                                          color: "#F15A24",
                                          flexShrink: 0,
                                        }}
                                      />
                                      <Typography
                                        className="subpara"
                                        sx={{ color: "#333333" }}
                                      >
                                        {e?.location}
                                      </Typography>
                                    </Stack>
                                  </Stack>

                                  <Button
                                    variant="outlined"
                                    sx={{
                                      borderColor: "#F15A24",
                                      color: "#F15A24",
                                      borderRadius: "15px",
                                      px: 3,
                                      py: 1,
                                      backgroundColor: "white",
                                      ":hover": {
                                        backgroundColor: "white",
                                        borderColor: "#F15A24",
                                      },
                                      textTransform: "none",
                                      mt: 1,
                                    }}
                                    onClick={() => handleViewDetails(e?.id)}
                                  >
                                    {isSelected ? "Hide Detail" : "View Detail"}
                                  </Button>
                                </Stack>
                              </Stack>
                            </Stack>

                            {isSelected && (
                              <Stack
                                sx={{
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderRadius: "15px",
                                  px: 1,
                                }}
                              >
                                {/* Job Description */}
                                <Stack gap={1} px={2} py={2}>
                                  <Typography
                                    className="mainHeading"
                                    sx={{ color: "#333333" }}
                                  >
                                    Job Description
                                  </Typography>
                                  <Stack
                                    sx={{
                                      borderRadius: "15px",
                                      border: "1px solid #F1F1F1",
                                    }}
                                    px={2}
                                    py={2}
                                  >
                                    <Typography
                                      className="mainPara"
                                      sx={{ color: "#868181" }}
                                    >
                                      {e?.description || ""}
                                    </Typography>
                                  </Stack>
                                </Stack>

                                {/* Timing Constraints >>>>>> */}
                                <Stack gap={1} px={2} py={2}>
                                  <Typography
                                    className="mainHeading"
                                    sx={{ color: "#333333" }}
                                  >
                                    Timing Constraints
                                  </Typography>
                                  <Stack
                                    sx={{
                                      borderRadius: "15px",
                                      border: "1px solid #F1F1F1",
                                    }}
                                    px={2}
                                    py={2}
                                  >
                                    <Typography
                                      className="mainPara"
                                      sx={{
                                        color: "#868181",
                                        textAlign: "start",
                                      }}
                                    >
                                      {e?.time || ""}
                                    </Typography>
                                  </Stack>
                                </Stack>

                                {/* Vendor Details >>>>>>>> */}
                                <Stack gap={1} px={2} py={2}>
                                  <Typography
                                    className="mainHeading"
                                    sx={{ color: "#333333" }}
                                  >
                                    Vendor Detail
                                  </Typography>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{
                                      borderRadius: "15px",
                                      border: "1px solid #F1F1F1",
                                    }}
                                    px={2}
                                    py={2}
                                  >
                                    <Stack>
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                      >
                                        <Typography
                                          className="mainPara"
                                          sx={{
                                            color: "black",
                                            fontWeight: "600 !important",
                                            fontSize: "16px !important",
                                          }}
                                        >
                                          Name :
                                        </Typography>
                                        <Typography
                                          className="mainPara"
                                          sx={{ color: "#868181" }}
                                        >
                                          {`${e?.vendor?.first_name || ""} ${
                                            e?.vendor?.last_name || ""
                                          }`}
                                        </Typography>
                                      </Stack>
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        gap={1}
                                      >
                                        <Typography
                                          className="mainPara"
                                          sx={{
                                            color: "black",
                                            fontWeight: "600 !important",
                                            fontSize: "16px !important",
                                          }}
                                        >
                                          Email :
                                        </Typography>
                                        <Typography
                                          // className="mainPara"
                                          sx={{ color: "#868181" }}
                                        >
                                          {`${e?.vendor?.email || ""} `}
                                        </Typography>
                                      </Stack>
                                    </Stack>

                                    {/* <Button
                                      variant="contained"
                                      startIcon={
                                        <HiMiniChatBubbleLeftRight
                                          size={17}
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      sx={{
                                        borderColor: "#F15A24",
                                        borderRadius: "15px",
                                        px: 3,
                                        py: 1,
                                        color: "white",
                                        backgroundColor: "#F15A24",
                                        ":hover": {
                                          backgroundColor: "#F15A24",
                                          borderColor: "#F15A24",
                                        },
                                        mx: 2,
                                        my: 2,
                                      }}
                                      onClick={() =>
                                        _handleVanderChat(
                                          e?.vendor?.id || "",
                                          e?.vendor?.first_name || "",
                                          e?.vendor?.last_name || "",
                                          e?.vendor?.avatar || ""
                                        )
                                      }
                                    >
                                      Chat Now
                                    </Button> */}
                                  </Stack>
                                </Stack>

                                {/* Reviews */}
                                <Stack gap={1} px={2} py={2}>
                                  <Typography
                                    className="mainHeading"
                                    sx={{ color: "#333333" }}
                                  >
                                    Post Review
                                  </Typography>

                                  <Stack alignItems={"center"}>
                                    <ReactStars
                                      count={5}
                                      onChange={ratingChanged}
                                      size={40}
                                      value={jobReview?.rating}
                                      // fontSize={200}
                                      activeColor="#ffd700"
                                    />
                                  </Stack>
                                  <Stack p={2}>
                                    <Input
                                      onChange={(e) =>
                                        setjobReview({
                                          ...jobReview,
                                          msg: e?.target?.value,
                                        })
                                      }
                                      value={jobReview?.msg}
                                      label="Write a review"
                                    />
                                    {post_review_status ===
                                    asyncStatus.LOADING ? (
                                      <Stack
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        my={1}
                                        // sx={{ height: "30vh", width: "100%" }}
                                      >
                                        <CircularProgress
                                          size={30}
                                          sx={{ color: themeOrange }}
                                        />
                                      </Stack>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          borderColor: "#F15A24",
                                          borderRadius: "15px",
                                          px: 3,
                                          py: 1,
                                          color: "white",
                                          backgroundColor: "#F15A24",
                                          ":hover": {
                                            backgroundColor: "#F15A24",
                                            borderColor: "#F15A24",
                                          },
                                          my: 2,
                                        }}
                                        onClick={() => handlePostReview(e?.id)}
                                      >
                                        Post
                                      </Button>
                                    )}
                                  </Stack>
                                </Stack>

                                {/* Multiple Images */}
                                {/* <Stack
                          gap={1}
                          className="jobs_images_scrollbar_wrapper"
                          direction={"row"}
                          alignItems={"center"}
                          sx={{
                            mx: 2,
                          }}
                        >
                          {e.images.map((image, i) => (
                            <img
                              key={i}
                              src={`${imgUrl}${image.image}`}
                              style={{
                                height: "105px",
                                width: "105px",
                                borderRadius: "15px",
                              }}
                            />
                          ))}
                        </Stack> */}

                                {/* <Button
                            variant="contained"
                            sx={{
                              borderColor: "#F15A24",
                              borderRadius: "15px",
                              px: 3,
                              py: 1,
                              color: "white",
                              backgroundColor: "#F15A24",
                              ":hover": {
                                backgroundColor: "#F15A24",
                                borderColor: "#F15A24",
                              },
                              mx: 2,
                              my: 2,
                            }}
                            onClick={() => handleCancel(e.id)}
                          >
                            CANCEL JOB
                          </Button> */}
                              </Stack>
                            )}
                          </Stack>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default MyJobs;
